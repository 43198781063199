<template>
	<v-row class="mb-6" justify="center" no-gutters>
		<div class="d-flex flex-column message-404-container">
			<h1>Cette page n'existe pas !</h1>
			<p class="more-404">Une montgolfière dans l'espace ? Vous vous êtes surement perdu en chemin...</p>
			<div class="image-404-container">
				<img src="@/assets/images/404.jpg" class="image-404" alt="" />
			</div>
		</div>
	</v-row>
</template>

<script>
export default {
	name: 'PageNotFound',
};
</script>

<style scoped>
.image-404 {
	max-width: 50%;
}
.image-404-container {
	text-align: center;
	margin-top: 50px;
}
.message-404-container {
	margin-top: 50px;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
}
.more-404 {
	margin-top: 20px;
}
@media only screen and (max-width: 600px) {
	.image-404 {
		max-width: 80%;
	}
	.more-error {
		padding-left: 30px;
		padding-right: 30px;
	}
}
</style>
